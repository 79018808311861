import UAParser from 'lib/ua-parser';

var mobileReg = /\bMobile(?:\/([\w\.]+))?\b/gi;
var parserExtensions = {
  browser: [
    [/(DuckDuckGo)\/([\w\.]+)/i], [UAParser.BROWSER.NAME, UAParser.BROWSER.VERSION]
  ]
};
var parser = new UAParser(parserExtensions);
var details = parser.getResult();

// assert basic obj structure is present
details.browser = details.browser || {};
details.device = details.device || {};

var browserName = details.browser.name;
if (browserName) {
  // replace whitespace with underscore
  // force to lower case
  browserName = browserName
    .trim()
    .replace(/\s+/g, '_')
    .toLowerCase();
}

// the library does not properly determine device type for duckduckgo on android
if (browserName === 'duckduckgo' &&
  !details.device.type &&
  details.ua &&
  mobileReg.test(details.ua)
) {
  details.device.type = 'mobile';
}

// return simplified object
export default {
  ua: details.ua,
  mobile: details.device.type === 'mobile',
  ie: browserName === 'ie' || browserName === 'iemobile',
  browser: browserName,
  version: details.browser.major,
  deviceType: (details.device.type || 'desktop').toLowerCase()
};
